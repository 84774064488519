<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g transform="translate(4.278 5.617)">
      <rect
        width="1.548"
        height="10.75"
        rx="0.774"
        transform="translate(3.884 7.009) rotate(-90)"
        fill="currentColor"
      />
      <rect
        width="1.548"
        height="8.274"
        rx="0.774"
        transform="translate(3.884 9.593) rotate(-90)"
        fill="currentColor"
      />
      <rect
        width="1.548"
        height="7.03"
        rx="0.774"
        transform="translate(3.884 12.199) rotate(-90)"
        fill="currentColor"
      />
      <path
        d="M243.367,147.062a.948.948,0,0,0-.946.947v4.2a.948.948,0,0,1-.946.946H228.2a.948.948,0,0,1-.946-.945V139.882a.949.949,0,0,1,.945-.946h9.252a1.02,1.02,0,0,0,1.027-.947,1.131,1.131,0,0,0-1.139-.947H228.2a2.846,2.846,0,0,0-2.838,2.839V152.21a2.846,2.846,0,0,0,2.839,2.838h13.274a2.846,2.846,0,0,0,2.838-2.839v-4.2A.948.948,0,0,0,243.367,147.062Z"
        transform="translate(-225.363 -137.043)"
        fill="currentColor"
      />
      <path
        d="M411.887,151.611l-.5-.5a2.142,2.142,0,0,0-3.029,0l-7.54,7.54a.664.664,0,0,0-.17.291l-.992,3.58a.662.662,0,0,0,.639.84h0a.664.664,0,0,0,.177-.024l3.58-.992a.664.664,0,0,0,.292-.17l7.54-7.541A2.147,2.147,0,0,0,411.887,151.611Zm-.937,2.092-.279.279-1.651-1.651.279-.279a.817.817,0,0,1,1.155,0l.5.5A.82.82,0,0,1,410.95,153.7Zm-8.306,7.663-1.394.386.386-1.395Zm5.439-8.1,1.651,1.651-5.832,5.832-1.651-1.651Z"
        transform="translate(-390.161 -149.757)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
